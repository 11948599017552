import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Column, Row, Image, Section, Text } from 'components'
import { Col } from 'react-bootstrap'
import Blog from 'images/blogs/blog-18.png'
import Feature1 from 'images/blogs/app_features/chat-support.png'
import Feature2 from 'images/blogs/app_features/topup-history.png'
import Feature3 from 'images/blogs/app_features/barcode-scanner.png'
import Feature4 from 'images/blogs/app_features/receipt.png'
import Feature5 from 'images/blogs/app_features/wallet-ledger.png'
import Feature6 from 'images/blogs/app_features/font.png'
import 'components/css/stories.css'
import digipayAppFeaturesData from '../../data/static/DigipayAppFeaturesData'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/DigipayAppFeatures`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '6 Useful Features In The Digipay App',
  },
  {
    property: 'og:description',
    content: 'Nasubukan mo na bang gamitin ang features na ito, ka-Digipay?',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="6 Useful Features In The Digipay App" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <h2
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          6 USEFUL FEATURES IN THE DIGIPAY APP
        </h2>

        <Section mb="sp4">
          <Row>
            {digipayAppFeaturesData.map((value, index) => {
              return (
                <Row>
                  <Row key={index} className="blog__feature">
                    <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
                      {value.featureTitle}
                    </Heading>
                    <Text>{value.featureDescription}</Text>
                  </Row>
                  <Row className="blog__feature-image-container">
                    <img
                      className="blog__feature-image"
                      src={value.featureImageSource}
                    />
                  </Row>
                </Row>
              )
            })}

            <br />
            <Text>
              Digipay app is available at Google Playstore. Laging tandaan,
              i-download ang latest version para ma-enjoy mo ang updated
              features nito. Hanggang sa muli, ka-Digipay!
            </Text>
          </Row>
        </Section>
      </Column>
    </Row>
  </Layout>
)
export default ReasonsWhy
