import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Column, Row, Image, Section, Text } from 'components'
import { Col } from 'react-bootstrap'
import Blog from 'images/blogs/blog-18.png'
import Feature1 from 'images/blogs/app_features/chat-support.png'
import Feature2 from 'images/blogs/app_features/topup-history.png'
import Feature3 from 'images/blogs/app_features/barcode-scanner.png'
import Feature4 from 'images/blogs/app_features/receipt.png'
import Feature5 from 'images/blogs/app_features/wallet-ledger.png'
import Feature6 from 'images/blogs/app_features/font.png'
import 'components/css/stories.css'

const digipayAppFeaturesData = [
  {
    featureImageSource: Feature1,
    featureTitle: '1. CHAT SUPPORT',
    featureDescription:
      'Your convenience is our priority, ka-Digipay. Kaya naman, we are only one chat away with our Chat Support app feature! Hindi ka na lalabas ng app para masagot ang iyong questions at concerns. Real-time na maa-address ‘yan ng aming Customer Support Team. Ayos ‘di ba?',
  },
  {
    featureImageSource: Feature2,
    featureTitle: '2. TOP-UP HISTORY',
    featureDescription:
      'Gusto mo bang malaman kung Approved, Pending o Cancelled ang iyong Top-Up request? Sa Top-Up History mo ‘yan makikita! Tandaan, ito ay magre-reset pagkatapos ng isang linggo na nagsisimula ng Lunes, 12:00AM hanggang Linggo, 11:59PM. Cancelled ang iyong status kung mali ang bangko na nailagay, malabo ang proof of payment, o putol ang reference number. Ginagamit mo ba ito, ka-Digipay?',
  },

  {
    featureImageSource: Feature3,
    featureTitle: '3. BARCODE SCANNER',
    featureDescription:
      'Sa Digipay, gusto naming makatipid ka sa oras at effort! Ilan sa aming billers katulad ng Meralco ay may Barcode Scan option upang mas mapadali ang iyong transaction. Hindi mo na kailangang i-type ang 26-digit reference number dahil kusa itong lalabas kapag ini-scan mo ang barcode sa mismong Meralco bill. Less mistakes, ‘di ba ka-Digipay?',
  },

  {
    featureImageSource: Feature4,
    featureTitle: '4. SMS, EMAIL & PRINT RECEIPT',
    featureDescription:
      'Sa isang araw, madaming transactions ang pwedeng mangyari sa iyong Digitindihan. Kaya ang pinakaimportante sa lahat ay may maipakita kang resibo. Tama ba, ka-Digipay? Sa Digipay app, hindi lang isa, kung hindi tatlo ang options mo para ibigay ito sa iyong mga suki. Pumunta lang sa ‘Sales Data’ at piliin ang transaction. You can choose via SMS, email or print. Tandaan, always send receipts!',
  },
  {
    featureImageSource: Feature5,
    featureTitle: '5. WALLET LEDGER',
    featureDescription:
      'Curious ka ba kung anu-ano ang mga pumasok at lumabas na transactions sa araw na ito? Wallet Ledger is the answer! Dito mo mamo-monitor ang galaw ng iyong Digipay wallet, credit man o debit ‘yan. Magre-reset ito pagkatapos ng isang araw, mula 12:00AM hanggang 11:59PM. Is this app feature helpful to you, ka-Digipay?',
  },
  {
    featureImageSource: Feature6,
    featureTitle: '6. FONT SIZE',
    featureDescription:
      'Masyado bang maliit ang mga text ng Digipay app para sa’yo? No need to worry, ka-Digipay! Select ‘More’, then ‘Display’, and click ‘Font Size’ upang ma-adjust ang laki o liit ng text sa iyong screen. Try it now!',
  },
]
export default digipayAppFeaturesData
